@tailwind base;
@tailwind components;
@tailwind utilities;

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}

.login-gradient {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);

}
.purple-gradient {
    box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
    background-image: linear-gradient(98deg, #C6A7FE, #9155FD 94%);
}
.purple-gradient:hover {
  box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
  background-image: linear-gradient(98deg, #8f61e3, #5a25bd 94%);
}
.hover-purple-gradient{
  box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
  background-image: linear-gradient(98deg, #8f61e3, #5a25bd 94%);
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* Targeting scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #8888889e; /* Color of the scrollbar thumb */
  border-radius: 2px; /* Rounded corners of the scrollbar thumb */
}

/* Targeting scrollbar track when the mouse is hovering over it */
::-webkit-scrollbar-track:hover {
  background-color: #716f6f; /* Color of the scrollbar track on hover */
}

/* Targeting scrollbar thumb when the mouse is hovering over it */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

/* Targeting scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: transparent; /* Color of the scrollbar corner */
}
#sidebar a.active {
  box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
  background-image: linear-gradient(98deg, #C6A7FE, #9155FD 94%);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 10px 20px 0px;
    border-radius: 0 40px 40px 0 ;
    width: 92%;
  }
  
  .css-1nmdiq5-menu{
    z-index: 999 !important;
  }
  #sidebar a.active p{
  color: white;
    }
  #sidebar a.active #active-icon {
        /* background-image: linear-gradient(319deg, #663dff 0%, #aa00ff 37%, #cc4499 100%); */
    color:white !important
  }

  #sidebar a.active #active-icon img {
    filter:brightness(1) invert(1);
  
  }
  #img-invert-active {
    filter:brightness(1) invert(1);
  }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.bg-gradient{
    background-color: #663dff;
background-image: linear-gradient(319deg, #663dff 0%, #aa00ff 37%, #cc4499 100%);
}
html.light #sidebar a.active {
  color: black;
  /* Add any other styles you want for the active state when body has class 'light' */
}
html.dark #sidebar a.active {
  color: black !important;
  /* Add any other styles you want for the active state when body has class 'light' */
}

[data-tooltip] {
  --arrow-size: 5px;
  position: relative !important;
  z-index: 2 !important;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + var(--arrow-size));
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%,  calc(0px - var(--arrow-size))) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size); /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top;   /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  z-index: 99999999999999999 !important;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it for the basic tooltip.

  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto !important;
  right: calc(100% + var(--arrow-size)) !important;
  bottom: 50% !important;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5) !important;
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1) !important;
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: var(--arrow-size) 0px var(--arrow-size) var(--arrow-size) !important;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9)!important;
  transform-origin: left!important;
  transform: translateY(50%) scaleX(0)!important;
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1)!important;
}



/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + var(--arrow-size))!important;
  bottom: 50%!important;
}

[data-tooltip-location="right"]:before {
  transform: translate(var(--arrow-size), 50%) scale(0.5)!important;
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(var(--arrow-size), 50%) scale(1)!important;
}

[data-tooltip-location="right"]:after {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0px!important;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent!important;
  transform-origin: right!important;
  transform: translateY(50%) scaleX(0)!important;
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1)!important;
}



/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + var(--arrow-size))!important;
  bottom: auto!important;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, var(--arrow-size)) scale(0.5)!important;
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, var(--arrow-size)) scale(1)!important;
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px var(--arrow-size) var(--arrow-size) var(--arrow-size)!important;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent!important;
  transform-origin: bottom!important;
}



.scaled-radio input[type="radio"] {
  transform: scale(1.5); /* Adjust the scale value as needed */
  margin-right: 8px; /* Adjust spacing as needed */
}

.scaled-radio {
  margin-bottom: 8px;
}

.scaled-radio label {
  cursor: pointer;
}

.scaled-checkbox input[type="checkbox"] {
  transform: scale(1.5); /* Adjust the scale value as needed */
  margin-right: 8px; /* Adjust spacing as needed */
}

.scaled-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.scaled-checkbox label {
  cursor: pointer;
}

.css-1nmdiq5-menu {
  z-index: 999999999 !important;
}
.css-5736gi-menu {
  z-index: 999999999 !important;
}